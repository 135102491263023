import { createApi } from "@reduxjs/toolkit/query/react";
import {
  ButtonClickProps,
  SessionContinueProps,
  QuestionFormProps,
  OpenLandingProps,
} from "./types";
import { createBaseQuery } from "../config/createBaseQuery";

const baseQuery = createBaseQuery(process.env.REACT_APP_API_URL ?? '');

export const landingApi = createApi({
  reducerPath: "landing",
  tagTypes: ["landing"],
  baseQuery,
  endpoints: (builder) => ({
    openLanding: builder.mutation<string, OpenLandingProps>({
      query: (arg) => ({
        url: `/open_landing`,
        method: "post",
        body: arg,
      }),
      invalidatesTags: ["landing"],
    }),
    sessionContinue: builder.mutation<string, SessionContinueProps>({
      query: (arg) => ({
        url: `/session_continue`,
        method: "post",
        body: arg,
      }),
      extraOptions: { maxRetries: 0 }
    }),
    sendQuestionForm: builder.mutation<string, QuestionFormProps>({
      query: (arg) => ({
        url: `/question`,
        method: "post",
        body: arg,
      }),
      invalidatesTags: ["landing"],
    }),
    buttonClick: builder.mutation<string, ButtonClickProps>({
      query: (data) => ({
        url: `/button_click`,
        method: "post",
        body: data,
      }),
      invalidatesTags: ["landing"],
    }),
  }),
});
export const {
  useButtonClickMutation,
  useOpenLandingMutation,
  useSessionContinueMutation,
  useSendQuestionFormMutation,
} = landingApi;
