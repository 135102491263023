import React, {useCallback, useEffect, useRef} from 'react';
import {Route, Routes} from "react-router-dom";
import {HeaderLayout} from "./layout/HeaderLayout/HeaderLayout";
import {FooterLayout} from "./layout/FooterLayout/FooterLayout";
import {BodyLayout} from "./layout/BodyLayout/BodyLayout";
import {FormLayout} from "./layout/FormLayout/FormLayout";
import {PrivacyLayout} from "./layout/Docs/PrivacyLayout/PrivacyLayout";
import {AgreementLayout} from "./layout/Docs/AgreementLayout/AgreementLayout";
import { scrollIntoView } from "seamless-scroll-polyfill";
import {
  useOpenLandingMutation,
  useButtonClickMutation,
  useSessionContinueMutation,
} from './store/services/landing';

function App() {
  const ref = useRef<any>(null);
  const [sessionContinue] =
    useSessionContinueMutation();

  useEffect(() => {
    ref.current = setInterval(() => sessionContinue({
      session_id: sessionStorage.getItem("land_session_id")!,
      is_land: true,
    }), 5000);

    return () => {
      if(ref.current){
        clearInterval(ref.current)
      }
    }
  }, [])
  const [openLanging] =
    useOpenLandingMutation();
  const [buttonClick] =
    useButtonClickMutation();
  useEffect(() => {
    openLanging({
      session_id: sessionStorage.getItem("land_session_id")!
    })
  }, []);
  const myRef = useRef<null | HTMLDivElement>(null);
  const navigateToForm = useCallback(() => {
    const {current} = myRef;
    if (current !== null){
      scrollIntoView(current, { behavior: "smooth"});
    }
  }, []);
  const onClick = () => {
    const url = 'https://trythis.demo.university.innopolis.ru/app';
    buttonClick({
      session_id: sessionStorage.getItem("land_session_id")!,
      button_name: "try_app"
    })
    window.open(url, '_blank');
  };
  return (
    <div className="App" style={{ margin: 0 }}>
      <Routes>
        <Route path="/" element={
          <React.Fragment>
            <HeaderLayout navigateToForm={navigateToForm} onClick={onClick} />
            <BodyLayout onClick={onClick} />
            <FormLayout myRef={myRef} />
            <FooterLayout onClick={onClick} />
          </React.Fragment>
        }/>
        <Route path="/privacy" element={
          <PrivacyLayout />
        }/>
        <Route path="/agreement" element={
          <AgreementLayout />
        }/>
      </Routes>
    </div>
  );
}

export default App;

