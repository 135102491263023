import styles from "../DocsLayout.module.scss";

export function AgreementLayout() {
  return (
    <div className={styles.container}>
      <div className={styles.doc}>
        <h1>Пользовательское соглашение</h1>
        <br/>
        Настоящее Пользовательское соглашение (далее – «Соглашение») заключается между Обществом с ограниченной
        ответственностью «ПРИМЕРЬ ЭТО» (далее - Правообладатель), в лице директора Абдульмановой Алсу Илдаровны,
        действующего на основании Устава и любым лицом (далее - Пользователем), намеревающимся использовать и/или
        использующим сайты ООО «ПРИМЕРЬ ЭТО», размещенные в сети Интернет по адресу
        https://trythis.demo.university.innopolis.ru (включая все его поддомены, как функционирующие на дату принятия
        Пользователем настоящего Соглашения, так и запускаемые и вводимые в эксплуатацию в течение всего срока его
        действия).

        Сайт TryThis не является средством массовой информации.
        <br/>
        Используя сайт, Вы соглашаетесь с условиями данного соглашения.
        <br/>
        Если Вы не согласны с условиями данного соглашения, не используйте сайт и веб-приложение TryThis!
        <br/>
        
        <br/>
        Права и обязанности сторон
        <br/>
        Пользователь имеет право:
        <br/>
        - осуществлять поиск информации на сайте
        <br/>
        - получать информацию на сайте
        <br/>
        - распространять информацию на сайте
        <br/>
        - требовать от администрации скрытия любой информации о пользователе
        <br/>
        - требовать от администрации скрытия любой информации переданной пользователем сайту
        <br/>
        - использовать информацию сайта в личных некоммерческих целях
        <br/>
        
        <br/>
        Администрация имеет право:
        <br/>
        - по своему усмотрению и необходимости создавать, изменять, отменять правила
        <br/>
        - ограничивать доступ к любой информации на сайте
        <br/>
        - создавать, изменять, удалять информацию
        <br/>
        - удалять учетные записи
        <br/>
        - отказывать в регистрации без объяснения причин
        <br/>
        
        <br/>
        Пользователь обязуется:
        <br/>
        - не распространять информацию, которая направлена на пропаганду войны, разжигание национальной, расовой или
        религиозной ненависти и вражды, а также иной информации, за распространение которой предусмотрена уголовная или
        административная ответственность
        <br/>
        - не нарушать работоспособность сайта
        <br/>
        - не передавать в пользование свою учетную запись и/или логин и пароль своей учетной записи третьим лицам
        <br/>
        - не размещать материалы рекламного, эротического, порнографического или оскорбительного характера, а также иную
        информацию, размещение которой запрещено или противоречит нормам действующего законодательства РФ
        <br/>
        - не использовать скрипты (программы) для автоматизированного сбора информации и/или взаимодействия с Сайтом и
        его Сервисами
        <br/>
        
        <br/>
        Администрация обязуется:
        <br/>
        - поддерживать работоспособность сайта за исключением случаев, когда это невозможно по независящим от
        Администрации причинам.
        <br/>
        - осуществлять разностороннюю защиту учетной записи Пользователя
        <br/>
        - предоставить всю доступную информацию о Пользователе уполномоченным на то органам государственной власти в
        случаях, установленных законом
        <br/>
        
        <br/>
        Ответственность сторон
        <br/>
        - администрация не несет никакой ответственности за услуги, предоставляемые третьими лицами
        <br/>
        - в случае возникновения форс-мажорной ситуации (боевые действия, чрезвычайное положение, стихийное бедствие
        и т. д.) Администрация не гарантирует сохранность информации, размещённой Пользователем, а также бесперебойную
        работу информационного ресурса
        <br/>
        
        <br/>
        Условия действия Соглашения
        <br/>
        Данное Соглашение вступает в силу при любом использовании данного сайта.
        <br/>
        Соглашение перестает действовать при появлении его новой версии.
        <br/>
        Администрация оставляет за собой право в одностороннем порядке изменять данное соглашение по своему усмотрению.
        <br/>
        Администрация не оповещает пользователей об изменении в Соглашении.
      </div>
    </div>
  );
}
