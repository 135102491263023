import styles from "./BodyLayout.module.scss";
import {Typography} from "antd";
import phone1 from "./../../assets/images/phone1.png";
import phone2 from "./../../assets/images/phone2.png";
import girl2 from "./../../assets/images/girl2.png";
import fireWork from "./../../assets/icons/firework.svg";
import arrow from "./../../assets/icons/arrow.svg";
import heart from "./../../assets/icons/heart.svg";
import btn from "../../assets/icons/btn.svg";
import React from "react";


interface Props {
  onClick: () => void;
}

export const BodyLayout: React.FC<Props> = ({onClick}) => {
  return (
    <div className={styles.container}>
      <div className={styles.whyUs}>
        <div className={styles.headerWithText}>
          <Typography className={styles.bigText}>
            Почему выбирают нас?
          </Typography>
        </div>
        <div className={styles.headerWithText}>
          <Typography className={styles.headerText}>
            Не нужно устанавливать
          </Typography>
          <Typography className={styles.simpleText}>
            Мы используем веб-приложение, поэтому достаточно только зарегистрироваться
          </Typography>
        </div>
        <div className={styles.headerWithText}>
          <Typography className={styles.headerText}>
            Универсальность решений
          </Typography>
          <Typography className={styles.simpleText}>
            Наше решение подбирает капсулу вещей, которые
            объединяются в подходящий под ситуацию образ
          </Typography>
        </div>
        <div className={styles.headerWithText}>
          <Typography className={styles.headerText}>
            Предложения в магазине
          </Typography>
          <Typography className={styles.simpleText}>
            Все вещи из подборки вы сможете заказать в
            интренет-магазине и виртуально примерить на изображении
          </Typography>
        </div>
      </div>
      <div className={styles.howToUse}>
        <div className={styles.smallHeader}>
          Как пользоваться?
        </div>
        <div className={styles.firstStep}>
          <div className={styles.leftPart}>
            <img src={phone1} alt={"телефон1"} className={styles.phone1} />
          </div>
          <div className={styles.rightPart}>
            <div className={styles.specialDescription}>
              <div className={styles.bigHeader}>
                <p>Как пользоваться приложением?</p>
              </div>
              <div className={styles.description}>
                <p className={styles.bigNum}>1</p>
                <p className={styles.text}>
                  Укажите свой пол и тип мероприятия
                </p>
                <img src={fireWork} alt={"салют"} className={styles.fireWork} />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.secondStep}>
          <div className={styles.leftPart}>
            <div className={styles.description}>
              <p className={styles.bigNum}>2</p>
              <p className={styles.text}>
                Настройте категорию одежды, которую вы хотите подобрать
              </p>
              <img src={arrow} alt={"стрелка"} className={styles.arrow} />
            </div>
          </div>
          <div className={styles.rightPart}>
            <img src={girl2} alt={"девушка2"} className={styles.girl2} />
          </div>
        </div>
        <div className={styles.thirdStep}>
          <div className={styles.leftPart}>
            <img src={phone2} alt={"телефон2"} className={styles.phone2} />
          </div>
          <div className={styles.rightPart}>
            <div className={styles.description}>
              <p className={styles.bigNum}>3</p>
              <p className={styles.text}>
                Система подберет подходящую одежду из интернет - магазинов
              </p>
              <img src={heart} alt={"сердце"} className={styles.heart} />
            </div>
          </div>
        </div>
        <div className={styles.tryContainer}>
          <Typography className={styles.tryHeader}>
            Гармоничный образ
          </Typography>
          <Typography className={styles.link}>
            Подберите одежду прямо сейчас за 200 ₽/месяц
          </Typography>
          <button className={styles.button} onClick={onClick}>
            <Typography className={styles.buttonText}>
              Попробовать
            </Typography>
            <img src={btn} alt={"кнопка"} className={styles.buttonImg} />
          </button>
          <Typography className={styles.footnote}>
            Мы направим ссылку на электронную почту после оплаты
          </Typography>
        </div>
      </div>
    </div>
  );
}