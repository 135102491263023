import styles from "./HeaderLayout.module.scss";
import logo from "../../assets/icons/logo.svg";
import btn from "../../assets/icons/btn.svg";
import girl from "../../assets/images/girl.png";
import girl1 from "../../assets/images/girl1.png";
import backBig1 from "../../assets/backgrounds/backBig1.svg";
import backBig2 from "../../assets/backgrounds/backBig2.svg";
import backSmall1 from "../../assets/backgrounds/backSmall.svg";
import backSmall2 from "../../assets/backgrounds/backSmall2.svg";
import {Typography} from "antd";
import useViewport from "../../hooks/useViewport";
import React from "react";

interface Props {
  navigateToForm: () => void;
  onClick: () => void;
}

export const HeaderLayout: React.FC<Props> = ({navigateToForm, onClick}) => {
  const viewport = useViewport();
  return (
    <div className={styles.container}>
      <img src={logo} alt={"логотип"} className={styles.logo} />
      <p className={styles.contactUs} onClick={navigateToForm}>Связаться с нами</p>
      <div className={styles.body}>
        <img src={backBig1} alt={"back"} className={styles.backBig1}/>
        <img src={backSmall1} alt={"backSmall"} className={styles.backSmall1}/>
        <img src={backBig2} alt={"back2"} className={styles.backBig2}/>
        <img src={backSmall2} alt={"backSmall2"} className={styles.backSmall2}/>
        <div className={styles.left}>
          <img src={viewport.width > 596 ? girl : girl1} alt={"девушка"} className={styles.girl} />
        </div>
        <div className={styles.right}>
          <div className={styles.text}>
            <Typography className={styles.header}>Try this</Typography>
            <Typography className={styles.subHeader}>
              Сделайте образ красивее и гармоничнее с нашим приложением
            </Typography>
          </div>
          <button className={styles.button} onClick={onClick}>
            <Typography className={styles.buttonText}>
              Попробовать
            </Typography>
            <img src={btn} alt={"кнопка"} className={styles.buttonImg} />
          </button>
        </div>
        <div className={styles.hashtags}>
          <div className={styles.hashtagText}>
            #Не нужно устанавливать
          </div>
          <div className={styles.hashtagText}>
            #Универсальность решений
          </div>
          <div className={styles.hashtagText}>
            #Предложения в магазине
          </div>
        </div>
      </div>
    </div>
  );
}
