import styles from "./FormLayout.module.scss";
import React from "react";
import girlForm from "../../assets/images/girlForm.png";
import { Form } from 'antd';
import backForm from "../../assets/backgrounds/backForm.svg";
import backFormSmall from "../../assets/backgrounds/backFormSmall.svg";
import { useSendQuestionFormMutation } from "../../store/services/landing";
import {
  useButtonClickMutation,
} from '../../store/services/landing';

type FieldType = {
};

interface Props {
  myRef: React.MutableRefObject<null | HTMLDivElement>;
}

export const FormLayout:React.FC<Props> = ({myRef}) => {
  const [form] = Form.useForm<FieldType>();
  const [sendQuestionForm] = useSendQuestionFormMutation();

  const [buttonClick] =
    useButtonClickMutation();
  const onFinish = () => {
    sendQuestionForm({
      session_id: sessionStorage.getItem("land_session_id")!,
    })
    const url = 'https://t.me/try_this_style_bot';
    buttonClick({
      session_id: sessionStorage.getItem("land_session_id")!,
      button_name: "try_app"
    })
    window.open(url, '_blank');
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className={styles.container} ref={myRef}>
      <img src={backForm} alt={"back"} className={styles.backForm}/>
      <img src={backFormSmall} alt={"back"} className={styles.backFormSmall}/>
      <div className={styles.left}>
        <div className={styles.text}>
          <p className={styles.heading}>Появились вопросы?</p>
          <p className={styles.subHeading}>Расскажем подробнее о продукте</p>
        </div>
        <img src={girlForm} alt={"девушка"} className={styles.girlForm} />
      </div>
      <div className={styles.right}>
        <Form
          form={form}
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item>
            <button className={styles.btn} type="submit">
              Отправить
            </button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

