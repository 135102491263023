import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query";
export function createBaseQuery(url: string) {
  const baseQuery = fetchBaseQuery({
    baseUrl: url,
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Headers":"*",
      "Access-Control-Allow-Origin":"*"
    }
  });
  return baseQuery
}
